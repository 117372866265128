<template>
  <div class="issued-rmpo">
    <div class="page-info">
      <div class="page-title">
        <p class="title-1">Received RMPO</p>
      </div>

      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <div class="d-flex mb-4 gutter-mx-3 search-toolbar justify-start">
        <!-- Column Select -->
        <div class="column-filter">
          <v-autocomplete
            dense
            solo
            hide-details
            flat
            outlined
            :items="searchColumns"
            v-model="searchColumn"
            item-value="searchValue">
            <template v-slot:append>
              <v-icon> mdi-chevron-down </v-icon>
            </template>
          </v-autocomplete>
        </div>

        <!-- Column Search -->
        <div class="search">
          <v-text-field
            dense
            outlined
            hide-details
            v-model="searchText"
            placeholder="Insert Keyword to search"
            background-color="white"
            @keyup.enter="getPOs(true)"></v-text-field>
        </div>

        <v-btn color="secondary" icon @click="getPOs(true)" style="margin: 0; align-self: center">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </div>

      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th v-for="column in tableColumns" :key="column.text">
                {{ column.text }}
              </th>
            </tr>
          </thead>
          <!-- #region po가 없을 경우의 tbody -->
          <tbody v-if="!hasPO">
            <tr>
              <td :colspan="tableColumns.length" class="no-data">There aren't any PO yet.</td>
            </tr>
          </tbody>
          <!-- #endregion -->
          <tbody>
            <tr
              v-for="(po, poIndex) in POs"
              :key="`tr ${po.rmpoUUID} ${poIndex}`"
              class="pointer"
              @click="rowClicked(po)">
              <!-- No -->
              <td>
                {{ totalCnt - poIndex - (page - 1) * itemsPerPage }}
              </td>
              <!-- PO# -->
              <td>
                <span v-if="hasValue(po.poNo)">
                  {{ po.poNo }}
                </span>
                <span v-else class="font-error" v-html="noDataFieldHTML"> </span>
              </td>
              <!-- buyer -->
              <td>
                {{ po.buyerName }}
              </td>
              <!-- Ship to -->
              <td>
                <span v-if="hasValue(po.shipTo)">
                  {{ po.shipTo }}
                </span>
                <span v-else class="font-error" v-html="noDataFieldHTML"> </span>
              </td>
              <!-- design# -->
              <td>
                {{ po.designNo }}
              </td>
              <!-- style# -->
              <td>
                {{ po.styleNo }}
              </td>
              <!-- brand -->
              <td>
                {{ po.offerBrand }}
              </td>
              <!-- ex-mill -->
              <td>
                <span v-if="hasValue(po.exMill)">
                  {{ $m(po.exMill).format("yyyy-MM-DD") }}
                </span>
                <span v-else class="font-error" v-html="noDataFieldHTML"> </span>
              </td>
              <!-- shipDate -->
              <td>
                <span v-if="hasValue(po.shipDate)">
                  {{ $m(po.shipDate).format("yyyy-MM-DD") }}
                </span>
                <span v-else class="font-error" v-html="noDataFieldHTML"> </span>
              </td>
              <!-- in House -->
              <td>
                <span v-if="hasValue(po.inHouse)">
                  {{ $m(po.inHouse).format("yyyy-MM-DD") }}
                </span>
                <span v-else class="font-error" v-html="noDataFieldHTML"> </span>
              </td>
              <!-- ShipMode -->
              <td>
                <span v-if="hasValue(po.shipMode)">
                  {{ po.shipMode }}
                </span>
                <span v-else class="font-error" v-html="noDataFieldHTML"> </span>
              </td>
              <!-- Items -->
              <td>
                {{ po.items }}
              </td>
              <!-- Qty -->
              <td v-html="`${po.qty}<br/>(${po.qtyUom})`"></td>
              <!-- Amount -->
              <td v-html="`${fixedNumber(po.amount, $mathFix, true)}<br/>(${po.amountUom})`"></td>
              <!-- modified -->
              <td>
                {{ $m(po.sysUdtDt).format("yyyy-MM-DD") }}
              </td>
              <!-- workedBy -->
              <td>
                {{ po.workedBy }}
              </td>
              <!-- status -->
              <td>
                {{ po.rcvPoStatus }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center pt-2" v-if="pageCount > 0">
          <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
        </div>
      </div>
    </v-card>

    <load-mcl :show.sync="loadMCLShow" :makePO="true" @load="mclLoaded" />

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import LoadMcl from "@/components/company/po/LoadMcl.vue";
import { hasValue, fixedNumber } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";

export default {
  components: { LoadMcl },
  data() {
    return {
      POs: [],
      searchColumn: "ALL",
      searchText: "",
      loading: false,
      loadMCLShow: false,
      page: 1,
      totalCnt: 0,
      itemsPerPage: 10,
      noDataFieldHTML: "Not<br/>Included",
      breadcrumbs: [
        {
          text: "Purchase Order",
          disabled: true,
        },
        {
          text: "Received RMPO",
          disabled: true,
        },
      ],
      columns: [
        //text : thead 표시
        //searchValue : 필터 시 서버에 전송하는 값, 없을 경우 필터 X
        //tableShow : table 표시 여부
        //searchShow : 필터 셀렉트박스 표시 여부
        { text: "ALL", searchValue: "ALL", tableShow: false },
        { text: "No", searchShow: false },
        {
          text: "PO #",
          searchValue: "poNo",
        },
        {
          text: "BUYER",
          searchValue: "buyerName",
        },
        {
          text: "Ship to",
          searchValue: "shipTO",
        },
        {
          text: "Design #",
          searchValue: "designNo",
        },
        {
          text: "Style #",
          searchValue: "styleNo",
        },
        {
          text: "Brand",
          searchValue: "offerBrand",
        },
        {
          text: "Ex-mill",
          searchValue: "exMill",
        },
        {
          text: "Ship Date",
          searchValue: "shipDate",
        },
        {
          text: "In House",
          searchValue: "inHouse",
        },
        {
          text: "Ship Mode",
          searchValue: "shipMode",
        },
        {
          text: "Items",
          searchShow: false,
        },
        {
          text: "Qty",
          searchShow: false,
        },
        {
          text: "Amount",
          searchShow: false,
        },
        {
          text: "Modified",
          searchShow: false,
        },
        {
          text: "WORKED BY",
          searchValue: "workedBy",
        },
        {
          text: "STATUS",
          searchValue: "rmpoStatus",
        },
      ],
    };
  },
  methods: {
    getPOs() {
      this.loading = true;
      this.$http
        .get("/rmpo/received/list", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            filterColumn: this.searchColumn,
            searchText: this.searchText,
            currentPageNo: this.page,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.POs = res.data.data;
            this.totalCnt = res.data.data[0]?.totalCnt || 0;
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    mclLoaded() {
      if (this.page === 1) this.getPOs();
      else this.page = 1;
    },
    rowClicked(po) {
      this.$router.push({ name: "receivedRMPODetail", params: { poUUID: po.rcvPoUUID } });
    },
    hasValue,
    fixedNumber,
  },
  computed: {
    tableColumns() {
      return this.columns.filter(c => c.tableShow !== false);
    },
    searchColumns() {
      return this.columns.filter(c => c.searchShow !== false);
    },
    pageCount() {
      return Math.ceil((this.totalCnt || 0) / this.itemsPerPage);
    },
    sortColumn() {
      return this.columns.find(c => hasValue(c.sort));
    },
    hasPO() {
      return hasValue(this.POs);
    },
  },
  watch: {
    page() {
      this.getPOs();
    },
  },
  created() {
    this.getPOs();
  },
};
</script>

<style></style>
