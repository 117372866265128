<template>
  <v-dialog v-model="dialogShow" width="1000px" content-class="load-mcl customEditModal company">
    <v-card class="pa-5">
      <h1>LOAD MCL</h1>

      <div class="mt-10 content">
        <div class="d-flex mb-4 gutter-mx-3 search-toolbar">
          <!-- Column Select -->
          <div class="column-filter">
            <v-autocomplete
              dense
              solo
              hide-details
              flat
              outlined
              :items="searchColumns"
              v-model="searchColumn"
              item-value="searchValue">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>
          </div>

          <!-- Column Search -->
          <div class="search">
            <v-text-field
              dense
              outlined
              hide-details
              v-model="searchText"
              placeholder="Insert Keyword to search"
              background-color="white"
              @keyup.enter="getItems()"></v-text-field>
          </div>

          <v-btn color="secondary" icon @click="getItems()" style="margin: 0; align-self: center">
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
        </div>

        <div class="custom-table">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(column, columnIndex) in tableColumns"
                  :key="`thead tr ${column.text} ${columnIndex}`"
                  :style="column.text === 'CHECKED' ? 'width: 24px; padding: 3px 0 0 0 !important;' : ''">
                  <!-- <v-checkbox v-if="column.text === 'CHECKED' && !createMode" color="secondary" v-model="allCheck" /> -->
                  <input type="checkbox" v-if="column.text === 'CHECKED' && !createMode" v-model="allCheck" />
                  <span v-else>
                    {{ column.text }}
                  </span>
                </th>
              </tr>
            </thead>
            <!-- 1. designNo로 그룹, 2. mclUUID로 그룹, 3. mtrlCate로 그룹, 4. spplrUUID로 그룹 -->
            <tbody>
              <tr v-if="items.length < 1">
                <td colspan="100" class="no-data">You can search Closed MCLs only</td>
              </tr>
              <tr v-for="(item, itemIndex) in items" :key="`tbody tr ${itemIndex}`">
                <td v-if="item.changeDesign" :rowspan="item.designNoRowspan">
                  {{ item.designNo }}
                </td>
                <td v-if="item.changeMclUUID" :rowspan="item.mclUUIDRowspan">
                  {{ hasValue(item.styleNo) ? item.styleNo.replaceAll("|$|", ", ") : "" }}
                </td>
                <td v-if="item.changeMclUUID" :rowspan="item.mclUUIDRowspan">
                  {{ item.mclName }}
                </td>
                <td v-if="item.changeMtrlCate" :rowspan="item.mtrlCateRowspan">
                  {{ item.mtrlCate }}
                </td>
                <td v-if="item.changeSpplrUUID" :rowspan="item.spplrUUIDRowspan" :class="getActiveLine(item)">
                  {{ item.spplrName }}
                </td>
                <td :class="getActiveLine(item)">
                  <!-- <v-checkbox
                    color="secondary"
                    @change="changeCheckbox(item)"
                    :disabled="checkDisabled(item)"
                    v-model="item.checked" /> -->
                  <input
                    type="checkbox"
                    v-model="item.checked"
                    @change="changeCheckbox(item)"
                    :disabled="checkDisabled(item)" />
                </td>
                <td :class="getActiveLine(item)">
                  {{ item.itemName }}
                </td>
                <td :class="getActiveLine(item)">
                  {{ item.itemNo }}
                </td>
                <td :class="getActiveLine(item)">
                  {{ item.rdNo }}
                </td>
                <td :class="getActiveLine(item)">
                  {{ item.color }}
                </td>
                <td :class="getActiveLine(item)">
                  {{ numberWithComma(item.balanceQty) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="actions d-flex justify-center">
          <v-btn color="secondary" dark :elevation="0" @click="makePO ? load() : getInfo()" :loading="loadLoading">
            LOAD
          </v-btn>
          <v-btn color="secondary" dark :elevation="0" @click="dialogShow = false">CANCEL</v-btn>
        </div>
      </div>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { hasValue, numberWithComma } from "@/utils/util";
export default {
  props: ["show", "makePO", "selectedArea", "selectedSpplrUUID", "createMode", "bomList"],
  data() {
    return {
      items: [],
      searchColumn: "ALL",
      searchText: "",
      loading: false,
      loadLoading: false,
      createModeSelectedMclUUID: "",
      tempSpplrUUID: "",
      columns: [
        { text: "ALL", searchValue: "ALL", tableShow: false },
        { text: "DESIGN #", searchValue: "designNo" },
        { text: "STYLE #", searchValue: "styleNo" },
        { text: "MCL TITLE", searchValue: "mclName" },
        { text: "MATERIAL CATEGORY", searchValue: "mtrlCate" },
        { text: "SUPPLIER COMPANY", searchValue: "spplrName" },
        { text: "CHECKED", searchShow: false },
        { text: "MILL / ITEM NAME", searchValue: "itemName" },
        { text: "MILL / ITEM #", searchValue: "itemNo" },
        { text: "RD # ", searchValue: "rdNo" },
        { text: "COLOR", searchValue: "color" },
        { text: "BALANCE", searchShow: false },
      ],
    };
  },
  methods: {
    getItems() {
      this.loading = true;
      this.$http
        .get("/rmpo/load/mcl", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            filterColumn: this.searchColumn,
            searchText: this.searchText,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            if (Array.isArray(res.data.data)) {
              const items = res.data.data;
              const length = items.length;

              let designNo = "";
              let designNoRowspan = 0;
              let changeDesignNoRowspanTarget = null;
              let mclUUID = "";
              let mclUUIDRowspan = 0;
              let changeMclUUIDRowspanTarget = null;
              let mtrlCate = "";
              let mtrlCateRowspan = 0;
              let changeMtrlCateRowspanTarget = null;
              let spplrUUID = "";
              let spplrUUIDRowspan = 0;
              let changeSpplrUUIDRowspanTarget = null;
              for (let i = 0; i < length; i++) {
                const item = items[i];

                item.checked = false;

                item.changeDesign = designNo !== item.designNo;
                if (item.changeDesign) {
                  if (changeDesignNoRowspanTarget) changeDesignNoRowspanTarget.designNoRowspan = designNoRowspan;
                  changeDesignNoRowspanTarget = item;
                  designNo = item.designNo;
                  designNoRowspan = 0;
                  mclUUID = "";
                }
                designNoRowspan += 1;

                item.changeMclUUID = mclUUID !== item.mclUUID;
                if (item.changeMclUUID) {
                  if (changeMclUUIDRowspanTarget) changeMclUUIDRowspanTarget.mclUUIDRowspan = mclUUIDRowspan;
                  changeMclUUIDRowspanTarget = item;
                  mclUUID = item.mclUUID;
                  mclUUIDRowspan = 0;
                  mtrlCate = "";
                }
                mclUUIDRowspan += 1;

                item.changeMtrlCate = mtrlCate !== item.mtrlCate;
                if (item.changeMtrlCate) {
                  if (changeMtrlCateRowspanTarget) changeMtrlCateRowspanTarget.mtrlCateRowspan = mtrlCateRowspan;
                  changeMtrlCateRowspanTarget = item;

                  mtrlCate = item.mtrlCate;
                  mtrlCateRowspan = 0;
                  spplrUUID = "";
                }
                mtrlCateRowspan += 1;

                item.changeSpplrUUID = spplrUUID !== item.spplrUUID;
                if (item.changeSpplrUUID) {
                  if (changeSpplrUUIDRowspanTarget) changeSpplrUUIDRowspanTarget.spplrUUIDRowspan = spplrUUIDRowspan;
                  changeSpplrUUIDRowspanTarget = item;

                  spplrUUID = item.spplrUUID;
                  spplrUUIDRowspan = 0;
                }
                spplrUUIDRowspan += 1;

                if (i === length - 1) {
                  changeDesignNoRowspanTarget.designNoRowspan = designNoRowspan;
                  changeMclUUIDRowspanTarget.mclUUIDRowspan = mclUUIDRowspan;
                  changeMtrlCateRowspanTarget.mtrlCateRowspan = mtrlCateRowspan;
                  changeSpplrUUIDRowspanTarget.spplrUUIDRowspan = spplrUUIDRowspan;
                }
              }

              this.items = res.data.data;
            }
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    load() {
      if (!this.items.some(i => i.checked)) return this.$toast.warning("There are no selected MCL");

      this.loadLoading = true;
      const mclPlanList = this.items.filter(i => i.checked).map(i => i.mclPlanId);
      this.$http
        .post("/rmpo/load/mcl", { cmpyUUID: this.$store.getters.getCmpyUUID, mclPlanList }, { timeout: 60 * 1000 })
        .then(res => {
          if (res.data.status === 200) {
            this.$emit("load");
            this.dialogShow = false;
          } else codeHandler(res.data);
        })
        .finally(() => (this.loadLoading = false));
    },
    getInfo() {
      if (!this.items.some(i => i.checked)) return this.$toast.warning("There are no selected MCL");

      this.loadLoading = true;
      const mclPlanList = this.items.filter(i => i.checked).map(i => i.mclPlanId);
      this.$http
        .get("/rmpo/load/mcl/list", { data: { cmpyUUID: this.$store.getters.getCmpyUUID, mclPlanList } })
        .then(res => {
          if (res.data.status === 200) {
            this.$emit("load", this.createMode ? res.data.data[0] : res.data.data[0].rmPoMclSelectDtoList);
            this.dialogShow = false;
          } else codeHandler(res.data);
        })
        .finally(() => (this.loadLoading = false));
    },
    checkDisabled(item) {
      // po에서 지정된 spplr가 아닐 경우에 비활성화,
      // 현재 area에서 선택된 mcl이 아닐경우에 비활성화,
      // 다른 area에서 사용되고 있는 mcl일 경우에 비활성화,
      // 이미 선택된 mcl plan일 경우에 비활성화,

      if (!this.makePO) {
        if (this.isCreateMode) {
          //spplrUUID가 정해져 있지 않음
          if (!hasValue(this.tempSpplrUUID)) return false;
          else return this.tempSpplrUUID !== item.spplrUUID || this.createModeSelectedMclUUID !== item.mclUUID;
        } else if (this.createMode) {
          //spplrUUID가 같지않거나, 이미 사용중인 mclList라면 비활성화
          const res1 = this.selectedSpplrUUID !== item.spplrUUID || this.usedMclList.includes(item.mclUUID);
          if (!hasValue(this.createModeSelectedMclUUID)) return res1;
          //현재 선택된 mclUUID가 아니라면 비활성화
          else return res1 || this.createModeSelectedMclUUID !== item.mclUUID;
        } else {
          //spplrUUID가 같지않거나, 이미 선택된 mclPlan이거나, mclUUID가 일치하지 않다면 비활성화
          return (
            this.selectedSpplrUUID !== item.spplrUUID ||
            this.selectedPlanList.includes(item.mclPlanId) ||
            this.selectedMclUUID !== item.mclUUID
          );
        }
      }
      return false;
    },
    getActiveLine(item) {
      // return this.selectedSpplrUUID === item.spplrUUID && item.mclUUID === this.selectedMclUUID ? "back-blue2" : "";

      //같은 SPPLR이면 컬러부여
      if (this.isCreateMode) return this.tempSpplrUUID === item.spplrUUID ? "back-blue2" : "";
      return this.selectedSpplrUUID === item.spplrUUID ? "back-blue2" : "";
    },
    changeCheckbox(item) {
      //spplrUUID가 없어서, 클릭한 checkbox의 spplrUUID로 설정 필요
      if (this.isCreateMode && item.checked && this.items.every(i => (i === item ? true : !i.checked))) {
        //최초로 check된 경우
        this.tempSpplrUUID = item.spplrUUID;
      } else if (this.createMode && this.items.every(i => !i.checked)) {
        //check가 다 풀린 경우
        this.tempSpplrUUID = "";
      }

      //생성모드일때는 설정된 mclUUID가 없음.
      //최초로 select한 checkbox의 mclUUID로 설정이 필요.
      if (this.createMode && item.checked && this.items.every(i => (i === item ? true : !i.checked))) {
        //최초로 check된  경우

        this.createModeSelectedMclUUID = item.mclUUID;
      } else if (this.createMode && this.items.every(i => !i.checked)) {
        //check가 다 풀린 경우
        this.createModeSelectedMclUUID = "";
      }
    },
    hasValue,
    numberWithComma,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    tableColumns() {
      return this.columns.filter(c => c.tableShow !== false);
    },
    searchColumns() {
      return this.columns.filter(c => c.searchShow !== false);
    },
    allCheck: {
      get() {
        if (!this.items || this.items.length === 0) return false;

        return this.items.every(i => {
          if (!this.checkDisabled(i) && !i.checked) return false;
          return true;
        });
      },
      set(value) {
        this.items.forEach(i => {
          if (!this.checkDisabled(i)) i.checked = value;
        });
      },
    },
    selectedPlanList() {
      //사용중인 모든 mcl Planning의 id List를 반환 (중복 방지용도)
      const mclList = this.selectedArea?.mclList;
      const planList = [];

      if (Array.isArray(mclList) && mclList.length > 0) {
        for (let mclIndex = 0; mclIndex < mclList.length; mclIndex++) {
          const list = mclList[mclIndex];
          const items = list.items;

          if (Array.isArray(items) && items.length > 0) {
            for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
              const item = items[itemIndex];
              planList.push(item.mclPlanId);
            }
          }
        }
      }

      return planList;
    },
    selectedMclUUID() {
      return this.selectedArea?.mclUUID;
    },
    usedMclList() {
      //area에서 최초로 loadMCL을 눌렀을 때, 호출한 PO에서 사용되고 있는 MCL UUID List를 반환
      // => area마다 MCL이 1:1로 배정되는데, 이미 호출된 MCL은 중복될 수 없기 때문.
      const usedMclList = [];
      if (this.createMode && Array.isArray(this.bomList) && this.bomList.length > 0) {
        for (let bomIndex = 0; bomIndex < this.bomList.length; bomIndex++) {
          const bom = this.bomList[bomIndex];
          if (hasValue(bom.mclUUID)) usedMclList.push(bom.mclUUID);
        }
      }
      return usedMclList;
    },
    isCreateMode() {
      //createMode는. area에서 최초로 loadMCL을 하는경우,
      //isCreateMode는 po에서 최초로 loadMCL을 하는 경우 (spplrUUID가 안정해져있음)
      return this.createMode && !hasValue(this.selectedSpplrUUID);
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.items.forEach(i => {
          i.checked = false;
          this.createModeSelectedMclUUID = "";
          this.tempSpplrUUID = "";
        });
      }
    },
  },
  created() {
    // this.getItems();
  },
};
</script>

<style></style>
